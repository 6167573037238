import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import { RequestMaintenancePlanWizardDatasourceProps } from '../RequestMaintenancePlanWizard'
import { COLORS } from '../../../../constants/themeConstants'
import i18next from 'i18next'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import FormAccordion from '../FormAccordion/FormAccordion'
import Spinner from '../../../atoms/Spinner/Spinner'
import Button from '../../../atoms/Button/Button'
import { ICONS } from '../../../../constants/iconConstants'
import NoResults from '../../../molecules/NoResults/NoResults'
import { useRequestMaintenancePlan } from '../../../../providers/RequestMaintenancePlanProvider'
import {
  URL_REQUEST_MAINTENANCE_PLAN_OVERVIEW,
  URL_REQUEST_MAINTENANCE_PLAN_USAGE_INFORMATION,
  URL_STEP,
} from '../../../../constants/urlConstants'
import { useHistory } from 'react-router-dom'
import MaintenanceHistoryForm from './MaintenanceHistoryForm'
import SpinnerWithLabel from '../../../atoms/SpinnerWithLabel/SpinnerWithLabel'

interface MaintenanceHistoryOverviewProps {
  vehicles: Record<string, Vehicle[]> | undefined
  datasource: RequestMaintenancePlanWizardDatasourceProps
}

const MaintenanceHistoryOverview = ({
  datasource,
  vehicles,
}: MaintenanceHistoryOverviewProps) => {
  const { setServiceJobsFormData } = useRequestMaintenancePlan()
  const [formErrors, setFormErrors] = useState<
    { vin: string; hasErrors: boolean }[]
  >([])
  const {
    vehicleOptions,
    isFetchingVehicleOptions,
    selectedVehicleType,
    isLoadingServiceJobs,
  } = useRequestMaintenancePlan()
  const history = useHistory()

  const handleFormErrorsChange = (hasErrors: boolean, vin: string) => {
    setFormErrors((prevErrors) => {
      const existingErrorIndex = prevErrors.findIndex((error) => error.vin === vin)
      if (existingErrorIndex !== -1) {
        const updatedErrors = [...prevErrors]
        updatedErrors[existingErrorIndex] = { vin, hasErrors }
        return updatedErrors
      } else {
        return [...prevErrors, { vin, hasErrors }]
      }
    })
  }
  const isFormValidByVin = (vin: string) => {
    const error = formErrors.find((error) => error.vin === vin)
    return error ? !error.hasErrors : true
  }

  // Create refs for each form
  const formRefs = useRef<Record<string, React.RefObject<any>>>({})

  useEffect(() => {
    if (vehicles && selectedVehicleType && vehicles[selectedVehicleType]) {
      vehicles[selectedVehicleType].forEach((vehicle) => {
        if (!formRefs.current[vehicle.vin]) {
          formRefs.current[vehicle.vin] = React.createRef()
        }
      })
    }
  }, [vehicles, selectedVehicleType])

  const changeStepToPreviousStepHandler = () => {
    const params = new URLSearchParams(location.search)
    params.set(URL_STEP, URL_REQUEST_MAINTENANCE_PLAN_USAGE_INFORMATION)
    history.replace(`${location.pathname}?${params.toString()}`)
  }

  const changeStepToVehicleOverviewStepHandler = () => {
    const params = new URLSearchParams(location.search)
    params.set(URL_STEP, URL_REQUEST_MAINTENANCE_PLAN_OVERVIEW)
    history.replace(`${location.pathname}?${params.toString()}`)
  }

  const handleSubmitAllForms = () => {
    const allFormsData: any[] = []
    Object.entries(formRefs.current).forEach(([vin, formRef]) => {
      if (formRef && formRef.current) {
        const formValues = formRef.current.getFormValues()
        if (formValues) {
          allFormsData.push({ vin, ...formValues })
        }
      }
    })

    setServiceJobsFormData(allFormsData)
    changeStepToVehicleOverviewStepHandler()
  }

  return (
    <Box>
      {isLoadingServiceJobs ? (
        <Flex
          sx={{
            width: '100%',
            height: '100%',
            paddingY: 5,
          }}
        >
          <SpinnerWithLabel
            label={i18next.t('commonLabels.loading')}
            sx={{
              width: 'max-content',
              position: 'sticky',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
        </Flex>
      ) : null}

      {vehicles &&
        !isLoadingServiceJobs &&
        selectedVehicleType &&
        vehicles[selectedVehicleType] &&
        vehicles[selectedVehicleType].length > 0 && (
          <>
            <Box>
              <Text
                variant="heading2"
                color={COLORS.BLACK}
                sx={{ fontSize: [4, 4, 4, 4] }}
              >
                {i18next.t(getTextFieldValue(datasource.stepThreeTitle), {
                  vehicleType: selectedVehicleType,
                })}
              </Text>
            </Box>
            <Box>
              <Text variant="bodyCompact" color={COLORS.GRAY}>
                {getTextFieldValue(datasource.stepThreeSubtitle)}
              </Text>
            </Box>
            <Box
              as="ul"
              role="list"
              sx={{
                listStyle: 'none',
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                marginBlockStart: 6,
              }}
            >
              {vehicles[selectedVehicleType].map((vehicle) => {
                return (
                  <li key={vehicle.vin}>
                    <FormAccordion
                      vinLabel={getTextFieldValue(datasource?.vin)}
                      accordionOpen={
                        vehicle.vin === vehicles[selectedVehicleType][0].vin
                      }
                      vehicle={vehicle}
                      isValid={isFormValidByVin(vehicle?.vin)}
                    >
                      <Box sx={{ padding: 3 }}>
                        {isFetchingVehicleOptions ? (
                          <Spinner />
                        ) : (
                          <Box>
                            {vehicle && (
                              <>
                                {!vehicle || isLoadingServiceJobs ? (
                                  <Box sx={{ marginBlockStart: 4 }}>
                                    <Spinner size={4} />
                                  </Box>
                                ) : null}
                                {!isFetchingVehicleOptions && vehicleOptions && (
                                  <>
                                    <MaintenanceHistoryForm
                                      key={vehicle.vin}
                                      vin={vehicle.vin}
                                      onFormErrorsChange={handleFormErrorsChange}
                                      ref={formRefs.current[vehicle.vin]}
                                      datasource={datasource}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </Box>
                        )}
                      </Box>
                    </FormAccordion>
                  </li>
                )
              })}
            </Box>
            <Box
              sx={{
                flexDirection: ['column', null, 'row-reverse'],
                display: 'flex',
                justifyContent: 'flex-start',
                marginBlockStart: 4,
                gap: 4,
              }}
            >
              <Box>
                <Button
                  variant="primary"
                  icon={ICONS.ARROW_RIGHT}
                  iconPosition="end"
                  sx={{ width: ['100%', '100%', null] }}
                  onClick={() => handleSubmitAllForms()}
                  disabled={formErrors.some((item) => item.hasErrors)}
                >
                  {getTextFieldValue(datasource.requestMaintenancePlanButton)}
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => changeStepToPreviousStepHandler()}
                  variant="outline"
                  icon={ICONS.ARROW_LEFT}
                  iconPosition="start"
                  sx={{ width: ['100%', '100%', null] }}
                >
                  {getTextFieldValue(datasource.previousButton)}
                </Button>
              </Box>
            </Box>
          </>
        )}

      {!vehicles ||
        (!vehicleOptions && !isFetchingVehicleOptions && (
          <Box sx={{ marginBlock: 5 }}>
            <NoResults
              description={getTextFieldValue(datasource.noResultsDescription)}
              title={getTextFieldValue(datasource.noResultsTitle)}
            >
              <Button
                as="a"
                href={datasource.backToMyFleetButton.url}
                variant="primary"
                icon={ICONS.ARROW_LEFT}
                iconPosition="start"
              >
                {datasource.backToMyFleetButton.text}
              </Button>
            </NoResults>
          </Box>
        ))}
    </Box>
  )
}

export default MaintenanceHistoryOverview
