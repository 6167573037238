import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../types/userProps'
import { createRequestConfig } from '../../../helpers/axiosHelper'

export interface VehicleServiceJobsProps {
  vin: string | undefined
  serviceJobs?: ServiceJobsEntity[] | null
}

export interface ServiceJobsResponse {
  messages: Message[]
}

interface Message {
  message: string
  severity: string
  code: string
  args: string[]
}

export interface ServiceJobsResponseValue {
  resultType: number
  faults?: FaultsEntity[] | null
  exceptions?: ExceptionsEntity[] | null
  data: ServiceJobsResponseData
}
export interface FaultsEntity {
  code: string
  message: string
  key: Key
  instance: Instance
}
export interface Key {
  fieldName: string
  value: string
}
export interface Instance {}
export interface ExceptionsEntity {
  code: string
  type: string
  message: string
  stackTrace: string
}
export interface ServiceJobsResponseData {
  serviceJobs?: ServiceJobsEntity[] | null
}
export interface ServiceJobsEntity {
  code: string
  name: string
  description: string
  weekFirst: number
  kmFirst: number
  weekInterval: number
  kmInterval: number
  requestToUserDate: boolean
  requestToUserMileage: boolean
}

const VehicleServiceJobs = (
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  payload: VehicleServiceJobsProps,
  user?: UserProps
): Promise<AxiosResponse<ServiceJobsResponse>> =>
  client.post('/vehicles/service-jobs', payload, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

export default VehicleServiceJobs
