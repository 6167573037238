import React, {
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'
import { Box, Flex, Text } from 'theme-ui'
import Spinner from '../../../atoms/Spinner/Spinner'
import { COLORS } from '../../../../constants/themeConstants'
import { ServiceJobsEntity } from '../../../../services/rest/dmsc/DMSCServiceJobs'
import { InputDateField } from '../../../atoms/FormFields/InputDateField'
import { ValidationRules } from '../../../../helpers/formFieldHelper'
import i18next from 'i18next'
import { InputMileageTextField } from '../../../atoms/FormFields/InputMileageTextField'
import { CheckboxField } from '../../../atoms/FormFields'
import { useRequestMaintenancePlan } from '../../../../providers/RequestMaintenancePlanProvider'
import { FormApi, ValidationErrors } from 'final-form'
import { Form } from 'react-final-form'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { alphaColor } from '../../../../helpers/themeHelper'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../../constants/iconConstants'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { RequestMaintenancePlanWizardDatasourceProps } from '../RequestMaintenancePlanWizard'

interface CustomFormData extends FormData {
  [key: string]: any
  estimatedAnnualDistance: number
}

interface MaintenanceHistoryFormProps {
  onFormErrorsChange: (hasErrors: boolean, vin: string) => void
  vin: string
  datasource: RequestMaintenancePlanWizardDatasourceProps
}

export interface MaintenanceHistoryFormRef {
  getFormApi: () => FormApi<CustomFormData, Partial<CustomFormData>> | undefined
  getFormValues: () => Partial<CustomFormData> | undefined
}

const MaintenanceHistoryForm = forwardRef<
  MaintenanceHistoryFormRef,
  MaintenanceHistoryFormProps
>(({ onFormErrorsChange, vin, datasource }, ref) => {
  const [breakpointM] = useBreakpoints('m')

  // Use a ref to track if we've already sent error state to parent
  // This helps prevent unnecessary re-renders
  const errorStateRef = useRef<boolean | null>(null)

  // Store form API in a ref instead of state to prevent re-renders
  const formApiRef = useRef<FormApi<CustomFormData, Partial<CustomFormData>>>()

  // Expose methods to parent component via ref
  useImperativeHandle(
    ref,
    () => ({
      getFormApi: () => formApiRef.current,
      getFormValues: () => formApiRef.current?.getState().values,
    }),
    []
  )

  const { isLoadingServiceJobs, filteredServiceJobs } = useRequestMaintenancePlan()
  const renderServiceJobFormItem = (serviceJob: ServiceJobsEntity) => (
    <>
      <InputDateField
        name={`${serviceJob.code}-executedDate`}
        value={undefined}
        validationRules={[
          ValidationRules.Required,
          ValidationRules.ShouldBeInThePast,
        ]}
        label={!breakpointM ? i18next.t('panels.requestMaintenanceServiceDate') : ''}
        aria-label={i18next.t('panels.requestMaintenanceServiceDate')}
        data-t-id={`panel-requestmaintenance-input-mileage-${serviceJob.code}`}
        disabled={
          formApiRef.current?.getFieldState(`checkbox-${serviceJob.code}`)?.value
        }
      />
      <InputMileageTextField
        value={undefined}
        label={
          !breakpointM ? i18next.t('panels.requestMaintenanceServiceMileage') : ''
        }
        aria-label={i18next.t('panels.requestMaintenanceServiceMileage')}
        name={`${serviceJob.code}-executedDistance`}
        data-t-id={`panel-requestmaintenance-input-mileage-${serviceJob.code}`}
        min={1000}
        disabled={
          formApiRef.current?.getFieldState(`checkbox-${serviceJob.code}`)?.value
        }
      />
      <CheckboxField
        name={`checkbox-${serviceJob.code}`}
        onChange={() => formApiRef.current?.mutators.resetValues(serviceJob.code)}
      >
        {i18next.t('panels.requestMaintenanceServiceNotPerformed')}
      </CheckboxField>
    </>
  )
  const resetFieldsState = (field: string) => {
    if (formApiRef.current) {
      formApiRef.current.resetFieldState(field)
    }
  }
  const validateForm = useCallback(
    (values: CustomFormData) => {
      const serviceJobsErrors: ValidationErrors = {}

      if (filteredServiceJobs) {
        filteredServiceJobs.forEach((serviceJob) => {
          const dateKey = `${serviceJob.code}-executedDate`
          const mileageKey = `${serviceJob.code}-executedDistance`
          const dateValue = (values as unknown as { [key: string]: string })[dateKey]
          const mileageValue = (values as unknown as { [key: string]: number })[
            mileageKey
          ]

          if (!dateValue && !values[`checkbox-${serviceJob.code}`]) {
            serviceJobsErrors[dateKey] = i18next.t('commonFormLabels.required')
            // check for step 4
            serviceJobsErrors.serviceJobs = i18next.t('commonFormLabels.required')
          }

          if (
            dateValue &&
            !values[`checkbox-${serviceJob.code}`] &&
            new Date(dateValue) >= new Date()
          ) {
            serviceJobsErrors[dateKey] = i18next.t(
              'commonFormLabels.shouldBeInTheFutureMessage'
            )
            // check for step 4
            serviceJobsErrors.serviceJobs = i18next.t(
              'commonFormLabels.shouldBeInTheFutureMessage'
            )
          }

          if (!mileageValue && !values[`checkbox-${serviceJob.code}`]) {
            serviceJobsErrors[mileageKey] = i18next.t('commonFormLabels.required')
            // check for step 4
            serviceJobsErrors.serviceJobs = i18next.t('commonFormLabels.required')
          }
        })
      }

      // Update error state outside the render cycle using ref
      const hasErrors = Object.keys(serviceJobsErrors).length > 0

      // Only notify parent if error state has changed
      if (errorStateRef.current !== hasErrors) {
        errorStateRef.current = hasErrors

        // Use requestAnimationFrame to avoid state updates during render
        requestAnimationFrame(() => {
          onFormErrorsChange(hasErrors, vin)
        })
      }

      return serviceJobsErrors
    },
    [filteredServiceJobs, onFormErrorsChange, vin]
  )

  return (
    <>
      <Form
        onSubmit={() => console.log('')}
        validate={validateForm}
        mutators={{
          resetValues: (args, state, utils) => {
            utils.changeValue(state, `${args}-executedDate`, () => null)
            utils.changeValue(state, `${args}-executedDistance`, () => 0)
            resetFieldsState(`${args}-executedDate`)
            resetFieldsState(`${args}-executedDistance`)
          },
        }}
        render={({ handleSubmit, form }) => {
          // Store form API in ref once - this won't trigger re-renders
          if (!formApiRef.current) {
            formApiRef.current = form
          }

          return (
            <form onSubmit={handleSubmit}>
              <Box>
                <Box>
                  <Box>
                    {isLoadingServiceJobs && (
                      <Box sx={{ marginTop: 4 }}>
                        <Spinner size={4} />
                      </Box>
                    )}
                    {filteredServiceJobs && (
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr 1fr'],
                          borderBottom: '1px solid',
                          borderColor: alphaColor(COLORS.DAF_BLUE, 0.12),
                          paddingBlockEnd: 4,
                          gap: 4,
                        }}
                      >
                        <Text variant="bodySmall">Service type</Text>
                        <Text variant="bodySmall">
                          {i18next.t('panels.requestMaintenanceServiceDate')}
                        </Text>
                        <Text variant="bodySmall">
                          {i18next.t('panels.requestMaintenanceServiceMileage')}
                        </Text>
                      </Box>
                    )}
                    {filteredServiceJobs ? (
                      filteredServiceJobs.map((serviceJob: ServiceJobsEntity) => (
                        <Fragment key={serviceJob.code}>
                          <Box
                            as="fieldset"
                            sx={{
                              border: 'none',
                              padding: 0,
                            }}
                          >
                            <Box
                              sx={{
                                paddingBlock: 4,
                                display: 'grid',
                                gridTemplateColumns: [
                                  '1fr',
                                  '1fr',
                                  '1fr 1fr 1fr 1fr',
                                ],
                                gap: 4,
                                alignItems: 'center',
                                border: 'none',
                                borderBottom: '1px solid',
                                borderColor: alphaColor(COLORS.DAF_BLUE, 0.12),
                                '&:last-child': {
                                  borderBottom: 'none',
                                },
                              }}
                            >
                              <Box sx={{ marginBlockEnd: 4 }}>
                                <Text
                                  as="legend"
                                  variant="bodySmall"
                                  color={COLORS.GRAY}
                                >
                                  {serviceJob.description}
                                </Text>
                              </Box>
                              {renderServiceJobFormItem(serviceJob)}
                            </Box>
                          </Box>
                        </Fragment>
                      ))
                    ) : (
                      <Flex
                        sx={{
                          gap: 2,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: '50%',
                            border: '2px solid',
                            borderColor: COLORS.GREEN,
                          }}
                        >
                          <IconWrapper
                            icon={ICONS.CHECK}
                            color={COLORS.GREEN}
                            size={4}
                          />
                        </Box>
                        <Text variant="bodySmall" color={COLORS.GRAY}>
                          {getTextFieldValue(datasource.noInputNeeded)}
                        </Text>
                      </Flex>
                    )}
                  </Box>
                </Box>
              </Box>
            </form>
          )
        }}
      />
    </>
  )
})
export default MaintenanceHistoryForm
