import React, { ReactNode } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../../constants/iconConstants'
import { COLORS } from '../../../../constants/themeConstants'
import { alphaColor } from '../../../../helpers/themeHelper'
import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import { getVehicleLabelForOverview } from '../../../../helpers/vehicleHelper'

interface FormAccordionProps {
  vehicle: Vehicle
  isValid: boolean
  accordionOpen?: boolean
  vinLabel: string
  children: ReactNode
}

const FormAccordion = ({
  vehicle,
  isValid,
  accordionOpen,
  vinLabel,
  children,
}: FormAccordionProps) => {
  return (
    <Box
      as="details"
      {...(accordionOpen ? { open: true } : {})}
      sx={{
        border: '1px solid',
        borderColor: 'gray.1',
        borderRadius: 8,
        backgroundColor: alphaColor(COLORS.DAF_BLUE, 0.12),
        summary: {
          backgroundColor: COLORS.WHITE,
        },
        '&[open]': {
          borderColor: alphaColor(COLORS.DAF_BLUE, 0.08),
          summary: {
            backgroundColor: alphaColor(COLORS.DAF_BLUE, 0.08),
            borderBlockEnd: '1px solid',
            borderColor: alphaColor(COLORS.DAF_BLUE, 0.08),
          },
        },
        '&[open] .icon': {
          rotate: '180deg',
        },
        overflow: 'hidden',
      }}
    >
      <Box
        as="summary"
        sx={{
          listStyle: 'none',
          '::-webkit-details-marker': {
            display: 'none',
          },
          paddingBlock: 4,
          paddingInline: 3,
          cursor: 'pointer',
        }}
      >
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Flex sx={{ flexDirection: ['column', 'column', 'row'], gap: 2 }}>
            <Box sx={{ minWidth: [null, null, '400px'] }}>
              <Text>{getVehicleLabelForOverview(vehicle)}</Text>
            </Box>
            <Box>
              <Text
                sx={{ fontSize: [1, 1, 2] }}
              >{`${vinLabel} ${vehicle.vin}`}</Text>
            </Box>
          </Flex>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Box
              className="checkmark"
              sx={{
                backgroundColor: isValid
                  ? COLORS.CONFIRMATION
                  : alphaColor(COLORS.DAF_BLUE, 0.08),
                borderRadius: '50%',
              }}
            >
              <IconWrapper icon={ICONS.CHECK} size={4} color={COLORS.WHITE} />
            </Box>

            <Box
              className="icon"
              sx={{
                backgroundColor: COLORS.DAF_BLUE,
                borderRadius: '50%',
                padding: 1,
              }}
            >
              <IconWrapper icon={ICONS.ARROW_DOWN} size={3} color={COLORS.WHITE} />
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box
        sx={{
          paddingBlockEnd: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default FormAccordion
