import { Box } from 'theme-ui'
import React, { ChangeEvent, Ref } from 'react'
import { COLORS } from '../../../../constants/themeConstants'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'

interface CheckboxProps {
  id?: string
  name?: string
  label?: string
  value?: string | number | readonly string[] | undefined
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  disabled?: boolean
  checked?: boolean
  ref?: Ref<HTMLInputElement>
}

const Checkbox = ({
  id,
  name,
  value,
  required = false,
  disabled = false,
  checked = false,
  onChange,
}: CheckboxProps) => {
  const { pageEditing } = useSitecoreContext()

  return (
    <Box
      sx={{
        input: {
          appearance: 'none',
          blockSize: 16,
          inlineSize: 16,
          border: '1px solid',
          borderColor: COLORS.DARK_GRAY,
          borderRadius: 2,
          backgroundColor: COLORS.WHITE,
          '::after': {
            content: '""',
            display: 'block',
            inlineSize: 4,
            blockSize: 8,
            border: '2px solid',
            borderColor: COLORS.WHITE,
            borderBlockStart: 0,
            borderInlineStart: 0,
            position: 'relative',
            insetInlineStart: 1,
            insetBlockStart: '1px',
            rotate: '45deg',
            opacity: 0,
          },
          ':checked': {
            backgroundColor: COLORS.DAF_BLUE,
            borderColor: COLORS.DAF_BLUE,
            '::after': {
              opacity: 1,
            },
          },
          ':focus-visible': {
            outline: '1px solid',
            outlineColor: COLORS.DARK_GRAY,
            outlineOffset: 1,
          },
        },
      }}
    >
      <input
        type="checkbox"
        id={id || `${name}-${value}`}
        name={name}
        value={value || name}
        required={required}
        checked={checked}
        onChange={onChange}
        {...(pageEditing ? { disabled: true } : { disabled })}
      />
    </Box>
  )
}
export default Checkbox
